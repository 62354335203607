import { useState, useEffect } from "react";

const Countdown = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const targetDate = new Date("November 13, 2025 00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Helper function to format numbers as two digits
  const formatNumber = (num: number) => String(num).padStart(2, "0");

  return (
    <div className="countdown">
      <div className="counter">
        <b>{formatNumber(countdown.days)}</b>
        <span>{countdown.days <= 1 ? `day` : `days`}</span>
      </div>
      {!isSmallScreen && (
        <>
          <div> </div>
          <div className="counter">
            <b>{formatNumber(countdown.hours)}</b>
            <span>{countdown.hours <= 1 ? `HR` : `HRS`}</span>
          </div>
          <div> </div>
          <div className="counter">
            <b>{formatNumber(countdown.minutes)}</b>
            <span>{countdown.minutes <= 1 ? `MIN` : `MINS`}</span>
          </div>
          <div> </div>
          <div className="counter">
            <b>{formatNumber(countdown.seconds)}</b>
            <span>{countdown.seconds <= 1 ? `SEC` : `SECS`}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Countdown;
