import logo from "./logo.png";
import "./App.css";
import Countdown from "./components/Countdown";
import CountdownMobile from "./components/CountdownMobile";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" className="logo" />
        <p className="App-text">Coming Soon</p>
        
        { window.innerWidth > 600 ? <Countdown /> : <CountdownMobile /> }

        <div className="contact">
          For inquiries, contact us at:
          <p className="App-link">
            icadubai@uaefma.com
            <br />+971 4 256 6622
          </p>
        </div>
      </header>
      <footer className="App-footer">
        © 2024–2025 48th ICA Conference & Exhibition, All Rights Reserved
      </footer>
    </div>
  );
}

export default App;
